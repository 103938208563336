<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
        :justify="justify"
        no-gutters
      >
        <v-col
          v-if="icon"
          :class="`text-${align}`"
          cols="12"
          class="mb-4"
        >
          <base-icon :color="color">
            {{ icon }}
          </base-icon>
        </v-col>

        <v-col
          v-if="title || subtitle"
          :cols="callout ? 9 : 12"
        >
          <base-subtitle
            v-if="subtitle"
            :title="subtitle"
            space="1"
          />

          <base-title
            :title="title"
            class="text-uppercase"
            space="0"
          />

          <base-divider :color="color" />

          <base-body
            v-if="shortdesc || $slots.default"
            :html="shortdesc"
            space="6"
          >
            <slot />
          </base-body>
          <v-dialog
            v-model="dialog"
            width="100%"
          >
            <template v-slot:activator="{ on }">
              <div class="text-right">
                <v-btn
                  color="blue lighten-2"
                  light
                  v-on="on"
                >
                  Read More &raquo;&raquo;
                </v-btn>
              </div>
            </template>

            <v-card>
              <v-card-title
                class="headline grey lighten-2"
                primary-title
              >
                {{ title }}
                <v-btn
                  color="primary"
                  text
                  style="float:right;"
                  @click="dialog = false"
                >
                  X
                </v-btn>
              </v-card-title>

              <v-card-text v-html="article">
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>

        <v-col
          v-if="callout"
          cols="2"
        >
          <div
            class="display-3 grey--text text--lighten-4 font-weight-bold pr-8"
            v-text="callout"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseNewsCard',

    mixins: [Heading],
    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      id: Number,
      active: Boolean,
      article: String,
      subtitle: String,
      newsdate: String,
      shortdesc: String,
      title: String,
      // text: String,
      // title: String,
    },
    data: () => ({
      dialog: false,
    }),
  }
</script>
