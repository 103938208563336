import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import moment from 'moment'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import $ from 'jquery'
import { firebase, Firestore } from '@/database/config/firebase'
import './plugins'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { ValidationProvider } from 'vee-validate'

Vue.component('ValidationProvider', ValidationProvider)
Vue.use(VueLodash, { lodash: lodash })
Vue.config.productionTip = false

firebase.auth().onAuthStateChanged((user) => {
  // Get some information from the Users
  // console.log('firebaseauth', user)
  const FireBaseUsers = Firestore.collection('users')
  if (user && user.uid) {
    FireBaseUsers.doc(user.uid)
      .get()
      .then((doc) => {
        const thisUser = doc.data()
        thisUser.id = doc.id
        thisUser.displayName = user.displayName
        thisUser.email = user.email

        store.dispatch('fetchUser', thisUser)
      })
  } else {
    store.dispatch('fetchUser', null)
  }
})

window.Vue = Vue

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('M/D/YYYY hh:mm a')
  }
})
Vue.filter('formatDateOnly', function (value) {
  if (value) {
    return moment(String(value)).format('M/D/YYYY')
  }
})
Vue.filter('toCurrency', function (value) {
  value = Number(value)
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })
  return formatter.format(value)
})
Vue.filter('FeeScheduleCurrency', function (value) {
  if (typeof value !== 'number') {
    // console.log(value + ' | ' + isNaN(value));
    if (isNaN(value)) {
      return value
    }
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })
  return formatter.format(value)
})
Vue.filter('toFixed', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  return (value / 1).toFixed(2)
})
Vue.filter('grossLossCurrency', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  if (value === -1) {
    return 'And Up'
  } else {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })
    return formatter.format(value)
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
