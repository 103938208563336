import { firebase, Firestore, Functions, Auth } from './config/firebase'
import FirebaseResults from './firebase_results'

const FirebaseUsers = Firestore.collection('users')
const FirebaseCompanies = Firestore.collection('companies')
const FirebaseVizions = Firestore.collection('vizions')
const FirebaseSystem = Firestore.collection('system')
const FirebaseTeam = Firestore.collection('team')
const FirebaseMail = Firestore.collection('mail')
const FirebaseTestimonials = Firestore.collection('testimonials')

export {
  firebase,
  Functions,
  Auth,
  FirebaseResults,
  FirebaseUsers,
  FirebaseCompanies,
  FirebaseVizions,
  FirebaseSystem,
  FirebaseTeam,
  FirebaseTestimonials,
  FirebaseMail,
}
