import firebase from 'firebase'
import 'firebase/firebase-firestore'
import 'firebase/firebase-auth'
import 'firebase/firebase-functions'
import 'firebase/firebase-storage'
import { firebaseConfig } from './settings'

firebase.initializeApp(firebaseConfig)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

const Firestore = firebase.firestore()
const Auth = firebase.auth()
const Functions = firebase.app().functions('us-central1')
const Storage = firebase.storage()
const googleProvider = new firebase.auth.GoogleAuthProvider()
const Persistence = firebase.auth.Auth.Persistence

export { firebase, Firestore, Auth, Functions, Storage, googleProvider, Persistence }
