const firebaseResults = () => {
  const map = docs => {
    const newResults = []
    docs.forEach(doc => {
      const newResult = doc.data()
      newResult.id = doc.id
      newResults.push(newResult)
    })
    return newResults
  }

  return Object.freeze({
    map,
  })
}

const FirebaseResults = firebaseResults()

export default FirebaseResults
