import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)
const vuexPersist = new VuexPersist({
  key: 'sales-vizion-app',
  storage: window.sessionStorage,
})

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
  },
  plugins: [vuexPersist.plugin],
  getters: {
    user (state) {
      return state.user
    },
    isLoggedIn: state => {
      // console.log('store-state.user.loggedIn', state.user.loggedIn)
      return state.user.loggedIn // !!state.user.loggedIn,
    },
  },
  mutations: {
    SET_LOGGED_IN (state, value) {
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    },
  },
  actions: {
    fetchUser ({ commit }, user) {
      // console.log('fetchUser', user)
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        // console.log('store-user', user)
        commit('SET_USER', user)
      } else {
        commit('SET_USER', null)
      }
    },
  },
})
