<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <form @submit.prevent="submit">
    <base-info-card
      :title="title"
      :subtitle="subtitle"
      space="4"
      color="primary"
    />

    <validation-provider
      v-slot="{ errors }"
      name="Name"
      rules="required"
    >
      <base-text-field
        id="name"
        v-model="contactform.name"
        label="Name"
        :error-messages="errors"
      />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      name="Loan Officer"
      rules="required"
    >
      <v-select
        v-model="contactform.loanofficer"
        :items="teamData"
        item-text="data.name"
        item-value="data.name"
        label="Loan Officer"
        outlined
        :error-messages="errors"
      ></v-select>
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      name="Phone"
      :rules="{ required: true, regex:/^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/ }"
    >
      <base-text-field
        id="phone"
        v-model="contactform.phone"
        label="Phone"
        :error-messages="errors"
      />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      name="Email"
      rules="required|email"
    >
      <base-text-field
        id="email"
        v-model="contactform.email"
        label="Email"
        :error-messages="errors"
      />
    </validation-provider>
    <v-select
      v-model="contactform.howcanwehelp"
      :items="howhelpoptions"
      item-text="label"
      item-value="value"
      label="How can we help?"
      outlined
    ></v-select>

    <v-switch
      v-model="contactform.receivemarketingmaterials"
      :label="`Receive Marketing And Promo Materials: ${(contactform.receivemarketingmaterials?'Yes':'No')}`"
    ></v-switch>

    <v-textarea
      v-model="contactform.note"
      outlined
      name="note"
      label="Note"
      rows="3"
    ></v-textarea>

    <vue-recaptcha
      ref="recaptcha"
      @verify="onVerify"
      :loadRecaptchaScript="true"
      sitekey="6LeJb3caAAAAAAVZHPotg4LRiJGpihUuODr9YVjX"
    >
    </vue-recaptcha>

    <base-btn
      :color="!theme.isDark ? &quot;accent&quot; : &quot;white&quot;"
      outlined
      target="_blank"
      @click="sendEmail"
      :disabled="invalid"
    >
      Send message
    </base-btn>
    </form>
    </validation-observer>
    <v-snackbar
      v-model="snackbar"
      :timeout="6000"
      rounded="pill"
      top
      color="green darken-4"
    >
      {{ returnMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="showError"
      :timeout="6000"
      rounded="pill"
      top
      color="red darken-4"
    >
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="showError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  // import * as api from '@/functions/api.js'
  import { FirebaseTeam } from '@/database/'
  import { Mail } from '@/database/mail'
  import VueRecaptcha from 'vue-recaptcha'
  import { required, email, max, regex } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

  setInteractionMode('eager')

  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })

  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })

  extend('email', {
    ...email,
    message: 'Email must be valid',
  })
  extend('regex', {
    ...regex,
    message: 'Phone must be formatted XXX-XXX-XXXX',
  })

  export default {
    name: 'BaseContactForm',

    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],
    components: {
      'vue-recaptcha': VueRecaptcha,
      ValidationProvider,
      ValidationObserver,
    },
    props: {
      subtitle: String,
      title: {
        type: String,
        default: 'MAIL US YOUR MESSAGE',
      },
    },
    data: () => ({
      contactform: {
        name: '',
        loanofficer: 'No Preferred Loan Officer',
        phone: '',
        email: '',
        howcanwehelp: 'I dont know where to start.',
        receivemarketingmaterials: false,
        note: '',
        robot: false,
      },
      blankcontactform: {
        name: '',
        loanofficer: 'No Preferred Loan Officer',
        phone: '',
        email: '',
        howcanwehelp: 'I dont know where to start.',
        receivemarketingmaterials: false,
        note: '',
        robot: false,
      },
      howhelpoptions: [
        {
          value: 'I dont know where to start.',
          label: 'I dont know where to start.',
        },
        {
          value: 'Purchase Information',
          label: 'Purchase Information',
        },
        {
          value: 'Refinance Information',
          label: 'Refinance Information',
        },
        {
          value: 'Non-Prime Financing',
          label: 'Non-Prime Financing',
        },
        {
          value: 'Reverse Mortgage Information',
          label: 'Reverse Mortgage Information',
        },
      ],
      teamData: [],
      returnMessage: 'Message Sent.',
      errorMessage: '',
      showError: false,
      snackbar: false,
    }),
    mounted () {
      this.getTeam()
    },
    methods: {
      validateForm () {
        let returnVal = true
        let returnMsg = ''
        // console.log(this.contactform)
        if (!this.contactform.robot) {
          returnVal = false
          this.showError = true
          returnMsg += 'Invalid reCaptcha'
        }
        if (this.showError) {
          this.errorMessage = returnMsg
        }
        return returnVal
      },
      onVerify: function (response) {
        if (response) this.contactform.robot = true
      },
      sendEmail: function () {
        // console.log('recaptcha:', this.form.robot)
        if (this.validateForm() && this.$refs.observer.validate()) {
          // Construct the object to send
          const mailRecord = {
            to: 'clint.steele@gmail.com',
            message: {
              subject: 'Contact Form Submission',
              text:
                'Name: ' +
                this.contactform.name +
                '\nPhone: ' +
                this.contactform.phone +
                '\nEmail: ' +
                this.contactform.email +
                '\nHowHelp: ' +
                this.contactform.howcanwehelp +
                '\nNote: ' +
                this.contactform.note +
                '\nAgree to Received Marketing and Promotional Materials: ' +
                (this.contactform.receivemarketingmaterials
                  ? 'YES'
                  : 'NO'),
              html:
                'Name: <strong>' +
                this.contactform.name +
                '</strong><br />Phone: <strong>' +
                this.contactform.phone +
                '</strong><br />Email: <strong>' +
                this.contactform.email +
                '</strong><br />HowHelp: <strong>' +
                this.contactform.howcanwehelp +
                '</strong><br />Note: <strong>' +
                this.contactform.note +
                '</strong><br />Agree to Received Marketing and Promotional Materials: <strong>' +
                (this.contactform.receivemarketingmaterials
                  ? 'YES'
                  : 'NO') +
                '</strong>',
            },
            formdata: this.contactform,
          }
          Mail.add(mailRecord)
          // this.contactform = this.blankcontactform
          this.returnMessage = 'Message Sent.'
          this.snackbar = true
          // console.log(form)
        }
      },
      getTeam () {
        this.teamData = []
        this.teamData.push({
          id: '',
          data: {
            name: 'No Preferred Loan Officer',
          },
        })
        FirebaseTeam.where('active', '==', true).where('ShowCommercial', '==', true)
          .orderBy('sortorder')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.teamData.push({
                id: doc.id,
                data: doc.data(),
              })
              // console.log(doc.id, ' => ', doc.data())
            })
            // console.log(this.teamData)
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })
      },
    },
  }
</script>
