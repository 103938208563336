const firebaseConfig = {
  apiKey: 'AIzaSyAb4J6-YcHWHZrrOFQfnSJSvv7Dk4A7NW0',
  authDomain: 'salesvizion-d3050.firebaseapp.com',
  projectId: 'salesvizion-d3050',
  storageBucket: 'salesvizion-d3050.appspot.com',
  messagingSenderId: '314108169601',
  appId: '1:314108169601:web:4d48f8be9b370707d0ac80',
}

export { firebaseConfig }
