import { FirebaseMail } from './index'

const mailDb = () => {
  const add = (mailInfo) => {
    return FirebaseMail.add(mailInfo)
  }

  const update = (docId, mailInfo) => {
    return FirebaseMail.doc(docId).update(mailInfo)
  }

  const remove = (docId) => {
    return FirebaseMail.doc(docId).delete()
  }

  const findOne = docId => {
    return FirebaseMail.doc(docId).get()
  }

  const findAll = () => {
    return FirebaseMail.where('active', '==', true).orderBy('sortorder')
  }

  return Object.freeze({
    add,
    update,
    findOne,
    findAll,
    remove,
  })
}

const Mail = mailDb()

export { Mail }
