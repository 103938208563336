// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/vizion',
      component: () => import('@/layouts/vizion/Index.vue'),
      children: [
        {
          path: '',
          name: 'Vizion Home',
          component: () => import('@/views/vizionhome/Index.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: () => import('@/views/vizion/Dashboard.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'edit/:vizionid',
          name: 'vizion',
          component: () => import('@/views/vizion/Vizion.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'display/:vizionid',
          name: 'viziondisplay',
          component: () => import('@/views/vizion/DisplayVizion.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'users',
          name: 'Users',
          component: () => import('@/views/vizion/Users.vue'),
          meta: {
            src: require('@/assets/project.jpg'), // Probably not going to have one here.
            requiresAuth: true,
          },
        },
        {
          path: 'users/:userid',
          name: 'User',
          component: () => import('@/views/vizion/User.vue'),
          meta: {
            src: require('@/assets/project.jpg'), // Probably not going to have one here.
            requiresAuth: true,
          },
        },
        {
          path: 'system',
          name: 'system',
          component: () => import('@/views/vizion/System.vue'),
          meta: {
            src: require('@/assets/project.jpg'), // Probably not going to have one here.
            requiresAuth: true,
          },
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import('@/views/profile/Index.vue'),
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'test',
          name: 'test',
          component: () => import('@/views/vizion/Test.vue'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/login',
      component: () => import('@/layouts/vizion/Index.vue'),
      children: [
        {
          path: '',
          name: 'login',
          component: () => import('@/views/login/Index.vue'),
        },
      ],
    },
    {
      path: '/register',
      component: () => import('@/layouts/vizion/Index.vue'),
      children: [
        {
          path: '',
          name: 'register',
          component: () => import('@/views/register/Index.vue'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/layouts/vizion/Index.vue'),
      children: [
        // {
        //   path: '',
        //   name: 'Home',
        //   component: () => import('@/views/home/Index.vue'),
        // },
        // {
        //   path: 'about',
        //   name: 'About',
        //   component: () => import('@/views/about/Index.vue'),
        //   meta: { src: require('@/assets/stock/about-stout.jpg') },
        // },
        // {
        //   path: 'contactus',
        //   name: 'Contact',
        //   component: () => import('@/views/contact-us/Index.vue'),
        //   meta: { src: require('@/assets/stock/contact-us-Device.jpg') },
        // },
        // {
        //   path: 'privacypolicy',
        //   name: 'PrivacyPolicy',
        //   component: () => import('@/views/privacypolicy/Index.vue'),
        //   meta: { src: require('@/assets/privacypolicy.jpg') }, // Pending
        // },
        // {
        //   path: 'news',
        //   name: 'News',
        //   component: () => import('@/views/News/Index.vue'),
        //   meta: {
        //     src: require('@/assets/stock/loan-product-street-sign.jpg'),
        //   },
        // },
        // {
        //   path: 'Apply',
        //   name: 'Apply',
        //   component: () => import('@/views/apply/Index.vue'),
        //   meta: { src: require('@/assets/branchlist.jpg') }, // Pending
        // },
        // {
        //   path: 'login',
        //   name: 'login',
        //   component: () => import('@/views/login/Index.vue'),
        //   meta: {
        //     src: require('@/assets/project.jpg'), // Probably not going to have one here.
        //   },
        // },
        // {
        //   path: 'team',
        //   name: 'team',
        //   component: () => import('@/views/team/Index.vue'),
        //   meta: { src: require('@/assets/stock/about-stout.jpg') },
        // },
        // {
        //   path: 'team/:memberid',
        //   name: 'teammember',
        //   component: () => import('@/views/team/member.vue'),
        //   meta: { src: require('@/assets/stock/about-stout.jpg') },
        // },
        {
          path: '',
          name: 'homeredirect',
          beforeEnter () {
            // location.href = 'https://www.salesvizion.com'
            location.href = '/vizion/dashboard'
          },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  // console.log('user', store.state.user)
  // console.log('user.data', store.state.user.data)
  // const thisUserData = store.state.user
  // console.log('thisUserData', thisUserData)
  // console.log('isLoggedIn', store.state.user.loggedIn)
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user.loggedIn) {
      next()
    } else {
      // Dispatch the Logout Function
      store.dispatch('fetchUser', null)
      // console.log('to', to)
      next({
        path: '/login',
        query: { message: 'Please Login.', returnPath: to.fullPath },
        replace: true,
      })
    }
  } else {
    next()
  }
})

export default router
